import axios, { AxiosRequestConfig } from 'axios';

export abstract class BaseService {

    protected get(url: string, params?: any): Promise<any> {
        return axios.get(url, { params } as AxiosRequestConfig);
    }

    protected post(url: string, data?: any): Promise<any> {
        return axios.post(url, data);
    }

    protected uploadFile(url: string, formData: FormData): Promise<any> {
        return axios.post(url,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    protected put(url: string, data?: any): Promise<any> {
        return axios.put(url, data);
    }

    protected delete(url: string, data?: any): Promise<any> {
        return axios.delete(url, data);
    }

}