
import { store } from '@/store';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { OrganisationDetails } from '@/models/OrganisationDetails';
import adminService from "@/services/AdminService";

@Module({
    namespaced: true,
    name: 'organisation',
    dynamic: true,    
    store    
})
class OrganisationModule extends VuexModule {
    private _organisation: OrganisationDetails = this.initialize();

    public get organisation() : OrganisationDetails{
        return this._organisation;
    }

    public initialize(): OrganisationDetails | null {
        const storedOrganisationDetails = localStorage.getItem('organisation');
        if (storedOrganisationDetails) {
            return JSON.parse(storedOrganisationDetails) as OrganisationDetails
        }

        return null;
    }

    @Mutation 
    commitSetOrganisation(organisation: OrganisationDetails) {
        this._organisation = organisation;
        localStorage.setItem('organisation', JSON.stringify(this._organisation));
    }

    @Mutation 
    commitClearOrganisation() {
        this._organisation = null;
        localStorage.removeItem("organisation");
    }

    @Action
    public async dispatchLoad(payload: {organisationId: string}): Promise<any> {

        this.commitClearOrganisation();

        const organisation = await adminService.getOrganisation(payload.organisationId);

        this.commitSetOrganisation(organisation);
    }

}

export const organisationModule = getModule(OrganisationModule);