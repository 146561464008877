import { OrganisationDetails } from "@/models/OrganisationDetails";
import { BaseService } from "./BaseService";
import { IConversionFactorType } from "@/models/ConversionFactorType";
import {
  IConversionFactor,
  IConversionFactorCreateRequest,
  IConversionFactorDeleteRequest,
} from "@/models/ConversionFactor";

class AdminService extends BaseService {
  public async getManualEmissions(
    organisationId: string,
    filter: any = null
  ): Promise<any> {
    const response = await this.get(
      `internal/organisations/${organisationId}/manualemissions`,
      filter
    );
    return response.data;
  }

  public async getConversionFactors(
    organisationId: string,
    conversionFactorTypeId: string
  ): Promise<IConversionFactor[]> {
    const response = await this.get(
      `internal/organisations/${organisationId}/conversionfactors/${conversionFactorTypeId}`
    );
    return response.data;
  }

  public async getAllConversionFactors(
    organisationId: string
  ): Promise<IConversionFactor[]> {
    const response = await this.get(
      `internal/organisations/${organisationId}/conversionfactors`
    );
    return response.data;
  }

  public async getOrganisation(
    organisationId: string
  ): Promise<OrganisationDetails> {
    const response = await this.get(`internal/organisations/${organisationId}`);
    return response.data as OrganisationDetails;
  }

  public async getOrganisations(): Promise<any> {
    const response = await this.get("internal/organisations");
    return response.data;
  }

  public async getImports(organisationId: string): Promise<any> {
    const response = await this.get(
      `internal/organisations/${organisationId}/imports`
    );
    return response.data;
  }

  public async getImport(
    organisationId: string,
    importId: string
  ): Promise<any> {
    const response = await this.get(
      `internal/organisations/${organisationId}/imports/${importId}`
    );
    return response.data;
  }

  public async deleteImport(organisationId: string, id: string): Promise<any> {
    const response = await this.delete(
      `internal/organisations/${organisationId}/imports/${id}`
    );
    return response.data;
  }

  public async reImport(organisationId: string, id: string): Promise<any> {
    const response = await this.put(
      `internal/organisations/${organisationId}/imports/${id}`
    );
    return response.data;
  }

  public async getInvoiceUrl(
    organisationId: string,
    invoiceId: string,
    type: string
  ): Promise<any> {
    const response = await this.get(
      `internal/organisations/${organisationId}/invoices/${invoiceId}/url?type=${type}`
    );
    return response.data;
  }

  public async getSuppliers(onlyActive: boolean, orgId: string): Promise<any> {
    var url = `internal/suppliers?onlyActive=${onlyActive}`;
    if (orgId) url += `&orgId=${orgId}`;

    const response = await this.get(url);
    return response.data;
  }

  public async getEmissionTypes(): Promise<any> {
    const response = await this.get("internal/emissiontypes");
    return response.data;
  }

  public async getDistances(): Promise<any> {
    const response = await this.get("internal/distances");
    return response.data;
  }

  public async findInvoices(organisationId: string, filter: any): Promise<any> {
    const response = await this.post(
      `internal/organisations/${organisationId}/invoices`,
      filter
    );
    return response.data;
  }

  public async createManualEmission(organisationId: string, emission: any) {
    await this.post(
      `internal/organisations/${organisationId}/manualemissions`,
      emission
    );
  }

  public async importManualEmissions(organisationId: string, batch: any) {
    await this.post(
      `internal/organisations/${organisationId}/manualemissions/import`,
      batch
    );
  }

  public async getConversionFactorTypes(): Promise<IConversionFactorType[]> {
    const response = await this.get("internal/conversionfactortypes");
    return response.data;
  }

  public async createConversionFactor(
    organisationId: string,
    factor: IConversionFactorCreateRequest
  ) {
    await this.post(
      `internal/organisations/${organisationId}/conversionfactors`,
      factor
    );
  }

  public async deleteConversionFactor(
    organisationId: string,
    factor: IConversionFactorDeleteRequest
  ) {
    await this.delete(
      `internal/organisations/${organisationId}/conversionfactors/${factor.emissionTypeId}/${factor.conversionFactorTypeId}/${factor.fromYear}/${factor.fromMonth}`
    );
  }

  public async aggregate(organisationId: string) {
    await this.put(`internal/organisations/${organisationId}/emissions`);
  }

  public async analyze(
    organisationId: string,
    supplierId?: string,
    fromInvoiceDate?: string
  ) {
    await this.post(
      `internal/organisations/${organisationId}/invoices/emissions`,
      { supplierId: supplierId, fromInvoiceDate: fromInvoiceDate }
    );
  }

  public async getInvoiceDetails(
    organisationId: string,
    invoiceId: string
  ): Promise<any> {
    const response = await this.get(
      `internal/organisations/${organisationId}/invoices/${invoiceId}`
    );
    return response.data;
  }

  public async importGeoAreaEmissions(data: FormData) {
    await this.uploadFile("internal/geoarea/emissions/import", data);
  }

  public async importGeoAreaEmissionsUpdate(data: FormData) {
    await this.uploadFile("internal/geoarea/emissions/importupdate", data);
  }

  public async importGeoAreaPopulation(data: FormData) {
    await this.uploadFile("internal/geoarea/population/import", data);
  }

  public async importGeoAreaGrp(data: FormData) {
    await this.uploadFile("internal/geoarea/grp/import", data);
  }

  public async updateOrganisationUnit(
    organisationId: string,
    organisationUnit: any
  ) {
    await this.put(
      `internal/organisations/${organisationId}/units/${organisationUnit.id}`,
      organisationUnit
    );
  }
}

const service: AdminService = new AdminService();
export default service;
